import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const NewLifeLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Khatibi"
    institution="New Life DPC"
    referralCode="NEWLIFE"
    physicianURL="https://newlifedpc.com/dr-khatibi/"
  />
)

export default NewLifeLandingPage
